class {
  onCreate(input) {
    this.state = {
      query: input.q,
      userInput: null,
      suggestions: [],
      selectedSuggestionIndex: -1,
      isFocused: false
    };

    // TODO: Figure out how to make this a static member
    this.KEY_CODES = {
      DOWN: 40,
      ENTER: 13,
      ESC: 27,
      UP: 38
    };
  }

  async handleInput(e) {
    const q = e.target.value;

    this.state.query = q;
    this.state.userInput = q;
    this.state.selectedSuggestionIndex = -1;
    this.state.isFocused = true;

    if (q.length) {
      const response = await (await fetch(`/api/suggestions?q=${encodeURIComponent(q)}`)).json();

      // Don't update the suggestions unless it still matches the input
      if (e.target.value == response[0]) {
        this.state.suggestions = response[1];
      }
    }
    // If the query is empty but we still have suggestions, reset them.
    else if (this.state.suggestions.length) {
      this.state.suggestions = [];
    }
  }

  handleKeyDown(e) {
    const { query, suggestions, selectedSuggestionIndex } = this.state;

    if (query.length) {
      if (e.keyCode == this.KEY_CODES.UP) {
        // Don't move cursor to the beginning of the text
        e.preventDefault();

        // Wrap around to the end
        if (selectedSuggestionIndex == -1) {
          this.selectSuggestion(suggestions.length - 1);
        }
        // Select the input box
        else if (selectedSuggestionIndex == 0) {
          this.revertToUserInput();
        } else {
          this.selectSuggestion(selectedSuggestionIndex - 1);
        }
      }
      // Down arrow: increment the index
      else if (e.keyCode == this.KEY_CODES.DOWN) {
        // Don't move cursor to the end of the text
        e.preventDefault();

        // Select in the input box
        if (selectedSuggestionIndex == suggestions.length - 1) {
          this.revertToUserInput();
        } else {
          this.selectSuggestion(selectedSuggestionIndex + 1);
        }
      }
      // Esc: reset values
      else if (e.keyCode == this.KEY_CODES.ESC) {
        // Revert if there's a selected index
        if (selectedSuggestionIndex != -1) {
          this.revertToUserInput();
        }
        // Clear suggestions if esc is pressed while search box is selected
        else {
          this.clearSuggestions();
        }
      }
    }
  }

  selectSuggestion(index) {
    this.state.selectedSuggestionIndex = index;
    this.state.query = this.state.suggestions[index];
  }

  revertToUserInput() {
    this.state.query = this.state.userInput;
    this.state.selectedSuggestionIndex = -1;
  }

  clearSuggestions(e) {
    this.state.suggestions = [];
  }

  handleBlur() {
    this.state.isFocused = false;
  }

  handleFocus() {
    this.state.isFocused = true;
  }
}
$ const { path } = input;

<div.searchBoxContainer>
  <form
    class=`searchbox ${state.suggestions.length && "hasSuggestions"} ${
      state.isFocused && "isFocused"
    }`
    action=`/${path}`
    method="get"
    on-submit("clearSuggestions")
  >
    <input
      name="q"
      value=state.query
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="off"
      placeholder="Search the web"
      spellCheck="false"
      autoFocus=input.autoFocus
      on-input("handleInput")
      on-keydown("handleKeyDown")
      on-focus("handleFocus")
      on-blur("handleBlur")
    >

    <if(state.suggestions && state.suggestions.length > 0)>
      <ol.suggestions>
        <for|suggestion, index| of=state.suggestions>
          <li class=(index == state.selectedSuggestionIndex && 'selected')>
            <a on-click("clearSuggestions") href=`/${path}?q=${encodeURIComponent(suggestion)}`>
              ${suggestion}
            </a>
          </li>
        </for>
      </ol>
    </if>
  </form>
</div>
